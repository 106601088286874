import React, { useState } from "react"
import "../components/styles.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import data from "./Navbar/mainDropdown.json"
import cleanerData from "./Navbar/cleanerDropdown.json"
import fluidData from "./Navbar/fluidDropdown.json"
import csrData from "./Navbar/csrDropdown.json"
function Sidemenu() {
  const [btnClick, SetBtnClick] = useState(true)

  const ToggleHandler = e => {
    console.log(btnClick)
    SetBtnClick(value => !value)
    if (btnClick === true) {
      console.log("Btn")
      document.getElementById("main-nav-dropdown").style.display = "block"
      document.getElementById("main-nav-dropdown2").style.display = "block"
    } else {
      document.getElementById("main-nav-dropdown").style.display = "none"
      document.getElementById("main-nav-dropdown2").style.display = "none"
    }
  }

  return (
    <div className="menu container">
      <div
        className="main-nav pr-5"
        data-sal="slide-left"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        <ul type="none" className="main-nav-ul">
          <li>
            <AniLink
              cover
              to="/"
              bg="#000"
              duration={2}
              className="main-nav-item line text-uppercase"
            >
              <span className="">Home</span>
            </AniLink>
          </li>
          <li>
            <AniLink
              cover
              to="/about"
              bg="#000"
              duration={2}
              className="main-nav-item line text-uppercase"
            >
              <span className="">About</span>
            </AniLink>
          </li>
          <li>
            <a
              href="#"
              className="main-nav-item dropdown-products line"
              onClick={ToggleHandler}
            >
              <span>Products</span>
            </a>
            <ul
              type="none"
              className="main-nav-dropdown pr-5"
              id="main-nav-dropdown"
            >
              <li>
                <a
                  href="#"
                  className="main-nav-dropdown-item headings text-uppercase"
                >
                  Fluid Enviro-Syn HCR Series
                </a>
                <ul className="dropdown-ul" type="none" id="main-nav-dropdown">
                  {fluidData.map((data, i) => (
                    <li key={i}>
                      <AniLink
                        paintDrip
                        to={data.link}
                        color="#000"
                        duration={1}
                        className="main-drop-down-item dropdown-ul-item text-uppercase"
                      >
                        <span>
                          {data.name}
                          <sup>
                            <small>&reg;</small>
                          </sup>
                        </span>
                      </AniLink>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
            <ul
              type="none"
              className="main-nav-dropdown item-ul pr-5"
              id="main-nav-dropdown2"
            >
              <li>
                <a
                  href="#"
                  rel="noreferrer"
                  className="main-nav-dropdown-item headings-1 headings text-uppercase"
                >
                  HCR Cleaner
                </a>
                <ul className="dropdown-ul" type="none">
                  {cleanerData.map((data, i) => (
                    <li key={i}>
                      <AniLink
                        paintDrip
                        to={data.link}
                        color="#000"
                        duration={1}
                        className="dropdown-ul-item text-uppercase"
                      >
                        <span>{data.name}</span>
                      </AniLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <a
                  href="#"
                  rel="noreferrer"
                  className="main-nav-dropdown-item headings-1 headings text-uppercase"
                >
                  CSR Cleaner Concentrate
                </a>
                <ul className="dropdown-ul" type="none">
                  {csrData.map((data, i) => (
                    <li key={i}>
                      <AniLink
                        paintDrip
                        to={data.link}
                        color="#000"
                        duration={1}
                        className="dropdown-ul-item text-uppercase"
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: data.name }}
                        ></span>
                      </AniLink>
                    </li>
                  ))}
                </ul>
              </li>
              {data.map((data, i) => (
                <li key={i}>
                  <AniLink
                    paintDrip
                    to={data.link}
                    color="#00101d"
                    duration={1}
                    className="main-nav-dropdown-item headings-1 text-uppercase"
                  >
                    <span>{data.name}</span>
                  </AniLink>
                </li>
              ))}
            </ul>
          </li>

          <li>
            <AniLink
              cover
              to="/contact"
              bg="#000"
              duration={1}
              className="main-nav-item line text-uppercase"
            >
              <span>Contact</span>
            </AniLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidemenu
