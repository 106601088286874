import React from "react"
import Header from "../Header"
import img from "../../img/navbar-logo.png"
import "../styles.css"
import Icons from "../Icons"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import data from "./mainDropdown.json"
import cleanerData from "./cleanerDropdown.json"
import fluidData from "./fluidDropdown.json"
import csrData from "./csrDropdown.json"
const Navbar = () => {
  return (
    <>
      <Header />
      <nav
        className=" navbar navbar-expand-lg navbar-dark pt-3 pt-lg-1 pt-md-3 px-0"
        data-sal="slide-down"
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal-easing="ease"
        data-sal-once="false"
      >
        <a
          className="navbar-brand"
          href="/"
          data-sal="slide-down"
          data-sal-duration="900"
          data-sal-delay="200"
          data-sal-easing="ease-in"
        >
          {/* <img src={logo} alt="Logo" className="svg-logo" /> */}
          <img src={img} alt="Logo" className="logo" />
        </a>

        <button
          className="navbar-toggler custom-toggler text-white border-0 p-0"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav d-lg-none">
            <li className="nav-item active">
              <AniLink
                cover
                to="/"
                bg="#000"
                duration={2}
                className="nav-link text-white"
              >
                Home <span className="sr-only">(current)</span>
              </AniLink>
            </li>
            <li className="nav-item">
              <AniLink
                cover
                to="/about"
                bg="#000"
                duration={2}
                className="nav-link text-white"
              >
                <span className="">About</span>
              </AniLink>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link text-white"
                href="#"
                rel="noreferrer"
                // role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                // aria-expanded="false"
              >
                Products
              </a>
              <ul
                className="dropdown-menu text-center"
                aria-labelledby="dropdownMenuLink"
              >
                <a
                  href="#"
                  className="heading-4 text-uppercase text-white dropdown-item"
                >
                  Fluid Enviro-Syn HCR Series
                </a>
                {fluidData.map((data, i) => (
                  <li key={i}>
                    <AniLink
                      paintDrip
                      to={data.link}
                      color="#000"
                      duration={1}
                      className="dropdown-item  text-capitalize "
                    >
                      {data.name}
                      <small>
                        <sup>&reg;</sup>
                      </small>
                    </AniLink>
                  </li>
                ))}

                <li>
                  <a
                    href="#"
                    rel="noreferrer"
                    className="heading-4 text-uppercase  text-white dropdown-item"
                  >
                    HCR Cleaner
                  </a>
                </li>
                {cleanerData.map((data, i) => (
                  <li key={i}>
                    <AniLink
                      paintDrip
                      to={data.link}
                      color="#000"
                      duration={1}
                      className=" text-uppercase  text-white dropdown-item "
                    >
                      {data.name}
                    </AniLink>
                  </li>
                ))}
                <li>
                  <a
                    href="#"
                    rel="noreferrer"
                    className="heading-4 text-uppercase  text-white dropdown-item"
                  >
                    CSR Cleaner Concentrate
                  </a>
                </li>
                {csrData.map((data, i) => (
                  <li key={i}>
                    <AniLink
                      paintDrip
                      to={data.link}
                      color="#000"
                      duration={1}
                      className=" text-uppercase  text-white dropdown-item "
                    >
                      {data.name}
                    </AniLink>
                  </li>
                ))}
                {/* Main Drop Down Items */}
                {data.map((data, i) => (
                  <li key={i}>
                    <AniLink
                      paintDrip
                      to={data.link}
                      color="#000"
                      duration={1}
                      className="heading-4 text-uppercase  text-white dropdown-item "
                    >
                      {data.name}
                    </AniLink>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item active">
              <AniLink
                cover
                to="/contact"
                bg="#000"
                duration={2}
                className="nav-link text-white"
              >
                Contact
              </AniLink>
            </li>
          </ul>
          <Icons className="icons-small" />
        </div>
      </nav>
    </>
  )
}

export default Navbar
